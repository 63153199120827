<template>
  <div class="content-wrapper">
    <div class="row content-header">
      <div class="content-header-left mb-2 col-12">
        <div class="row breadcrumbs-top">
          <div class="col-12 d-flex align-items-center justify-content-between">
            <h2 class="content-header-title float-left pr-1 mb-0">
              Update Plan
            </h2>
            <router-link
                class="btn btn-primary"
                :to="{ name: 'apps-plan-list'}"
            >
              List Plan
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="content-body">
      <div class="row match-height">
        <div class="col-12">
          <div
              data-v-5cff0742=""
              class="card"
          ><!----><!---->
            <div
                data-v-5cff0742=""
                class="card-header"
            />
            <div
                data-v-5cff0742=""
                class="card-body"
            ><!----><!---->
              <form
                  data-v-5cff0742=""
                  class="col-12 row"
              >
                <div
                    data-v-5cff0742=""
                    class="row col-12"
                >
                  <div
                      data-v-5cff0742=""
                      class="col-12"
                  >
                    <div
                        role="group"
                        class="form-row form-group"
                        data-v-5cff0742=""
                    >
                      <div class="col">
                        <label
                            for="h-first-name"
                            class="col-md-4 col-form-label"
                        >
                          Tên plan</label>
                        <input
                            v-model="name"
                            required
                            type="text"
                            placeholder="Name"
                            class="form-control col-11"
                        ><!----><!----><!----></div>
                    </div>
                  </div>
                  <div
                      data-v-5cff0742=""
                      class="col-12"
                  >
                    <div
                        id="__BVID__824"
                        role="group"
                        class="form-row form-group"
                        data-v-5cff0742=""
                    >
                      <div class="col">
                        <label
                            id="__BVID__824__BV_label_"
                            for="h-first-name"
                            class="col-md-4 col-form-label"
                        >
                          Băng thông</label>
                        <input
                            id="h-first-name"
                            v-model="speed"
                            required
                            type="text"
                            placeholder="Băng thông"
                            class="form-control col-11"
                        ><!----><!----><!----></div>
                    </div>
                  </div>
                  <div
                      data-v-5cff0742=""
                      class="col-12"
                  >
                    <div
                        role="group"
                        class="form-row form-group"
                        data-v-5cff0742=""
                    >
                      <div class="col">
                        <label
                            for="h-first-name"
                            class="col-md-4 col-form-label"
                        >
                          Số lượng ip backend</label>
                        <input
                            v-model="num_ip"
                            required
                            min="1"
                            type="number"
                            placeholder="cpu"
                            class="form-control col-11"
                        ><!----><!----><!----></div>
                    </div>
                  </div>
                  <div
                      data-v-5cff0742=""
                      class="col-12"
                  >
                    <div
                        id=""
                        role="group"
                        class="form-row form-group"
                        data-v-5cff0742=""
                    >
                      <div class="col">
                        <label
                            id=""
                            for="h-first-name"
                            class="col-md-4 col-form-label"
                        >
                          Giá</label>
                        <input
                            id=""
                            v-model="price"
                            required
                            type="number"
                            min="1"
                            placeholder="ram"
                            class="form-control col-11"
                        ><!----><!----><!----></div>
                    </div>
                  </div>
                  <div
                      data-v-5cff0742=""
                      class="col-12"
                  >
                    <div
                        id="__BVID__826"
                        role="group"
                        class="form-row form-group"
                        data-v-5cff0742=""
                    >
                      <div class="col form-group">
                        <label
                            id="__BVID__826__BV_label_"
                            class="col-md-4 col-form-label"
                        >Chống syn fload</label>
                        <select
                            id=""
                            v-model="deny_syn_fload"
                            class="form-control col-11"
                            name=""
                            required
                        >
                          <option value="1">ON</option>
                          <option value="0">OFF</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div
                      data-v-5cff0742=""
                      class="col-12"
                  >
                    <div
                        role="group"
                        class="form-row form-group"
                        data-v-5cff0742=""
                    >
                      <div class="col form-group">
                        <label
                            class="col-md-4 col-form-label"
                        >Chống botnet</label>
                        <select
                            v-model="deny_botnet"
                            class="form-control col-11"
                            name=""
                            required
                        >
                          <option value="1">ON</option>
                          <option value="0">OFF</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div
                      data-v-5cff0742=""
                      class="col-12"
                  >
                    <div
                        role="group"
                        class="form-row form-group"
                        data-v-5cff0742=""
                    >
                      <div class="col form-group">
                        <label
                            class="col-md-4 col-form-label"
                        >Chống udp fload</label>
                        <select
                            id=""
                            v-model="deny_udp_fload"
                            class="form-control col-11"
                            name=""
                            required
                        >
                          <option value="1">ON</option>
                          <option value="0">OFF</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div
                      data-v-5cff0742=""
                      class="col-12"
                  >
                    <div
                        role="group"
                        class="form-row form-group"
                        data-v-5cff0742=""
                    >
                      <div class="col form-group">
                        <label
                            class="col-md-4 col-form-label"
                        >Chống icmp/icmp</label>
                        <select
                            id=""
                            v-model="deny_icmp"
                            class="form-control col-11"
                            name=""
                            required
                        >
                          <option value="1">ON</option>
                          <option value="0">OFF</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div
                      data-v-5cff0742=""
                      class="col-12"
                  >
                    <div
                        role="group"
                        class="form-row form-group"
                        data-v-5cff0742=""
                    >
                      <div class="col form-group">
                        <label
                            class="col-md-4 col-form-label"
                        >Chống gre fload</label>
                        <select
                            id=""
                            v-model="deny_gre_fload"
                            class="form-control col-11"
                            name=""
                            required
                        >
                          <option value="1">ON</option>
                          <option value="0">OFF</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div
                      data-v-5cff0742=""
                      class="col-12"
                  >
                    <div
                        role="group"
                        class="form-row form-group"
                        data-v-5cff0742=""
                    >
                      <div class="col form-group">
                        <label
                            class="col-md-4 col-form-label"
                        >Chống tos fload</label>
                        <select
                            id=""
                            v-model="deny_tos_fload"
                            class="form-control col-11"
                            name=""
                            required
                        >
                          <option value="1">ON</option>
                          <option value="0">OFF</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div
                      data-v-5cff0742=""
                      class="col-12"
                  >
                    <div
                        role="group"
                        class="form-row form-group"
                        data-v-5cff0742=""
                    >
                      <div class="col form-group">
                        <label
                            class="col-md-4 col-form-label"
                        >Chống slowloris attack</label>
                        <select
                            id=""
                            v-model="deny_slowloris_attack"
                            class="form-control col-11"
                            name=""
                            required
                        >
                          <option value="1">ON</option>
                          <option value="0">OFF</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div
                      data-v-5cff0742=""
                      class="col-12"
                  >
                    <div
                        role="group"
                        class="form-row form-group"
                        data-v-5cff0742=""
                    >
                      <div class="col form-group">
                        <label
                            class="col-md-4 col-form-label"
                        >Chống http fload </label>
                        <select
                            id=""
                            v-model="deny_http_fload"
                            class="form-control col-11"
                            name=""
                            required
                        >
                          <option value="1">ON</option>
                          <option value="0">OFF</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div
                      data-v-5cff0742=""
                      class="col-12"
                  >
                    <div
                        role="group"
                        class="form-row form-group"
                        data-v-5cff0742=""
                    >
                      <div class="col form-group">
                        <label
                            class="col-md-4 col-form-label"
                        >Kiểm soát cổng truy cập</label>
                        <select
                            id=""
                            v-model="control_port"
                            class="form-control col-11"
                            name=""
                            required
                        >
                          <option value="1">ON</option>
                          <option value="0">OFF</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div
                      data-v-5cff0742=""
                      class="col-12"
                  >
                    <div
                        role="group"
                        class="form-row form-group"
                        data-v-5cff0742=""
                    >
                      <div class="col form-group">
                        <label
                            class="col-md-4 col-form-label"
                        >Giới hạn số lượng kết nối </label>
                        <select
                            id=""
                            v-model="limit_connection"
                            class="form-control col-11"
                            name=""
                            required
                        >
                          <option value="1">ON</option>
                          <option value="0">OFF</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                    data-v-5cff0742=""
                    class="offset-md-4 col"
                >
                  <button
                      data-v-5cff0742=""
                      type="button"
                      class="btn m-5 btn-primary"
                      @click.prevent="submitUpdatePlan()"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div><!----><!----></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex/dist/vuex.esm.browser'
import { mapGetters } from 'vuex'

export default {

  name: 'PlanEdit',
  data() {
    return {
      name: '',
      description: '',
      file: '',
      file1: '',
      file2: '',
      file3: '',
      file4: '',
      image: '',
      category: '',
      ram: 1,
      cpu: 1,
      sale_price: 0,
      url: null,
      region: 1,
      listRegion: [],
      speed: 0,
      num_ip: 0,
      price: 0,
      deny_botnet: 1,
      deny_syn_fload: 1,
      deny_udp_fload: 1,
      deny_icmp: 1,
      deny_gre_fload: 1,
      deny_slowloris_attack: 1,
      deny_http_fload: 1,
      deny_tos_fload: 1,
      limit_connection: 1,
      control_port: 1,
    }
  },
  created() {
    this.getPlanDetail(this.$route.params.id).then(r=>{
      console.log('getPlanDetail', r)
      console.log('r.data.speed',r.data.speed)
      this.name = r.data.name
      this.speed = r.data.speed
      this.num_ip = r.data.num_ip
      this.price = r.data.price
      this.deny_botnet = r.data.deny_botnet
      this.deny_syn_fload = r.data.deny_syn_fload
      this.deny_udp_fload = r.data.deny_udp_fload
      this.deny_icmp = r.data.deny_icmp
      this.deny_gre_fload = r.data.deny_gre_fload
      this.deny_tos_fload = r.data.deny_tos_fload
      this.deny_slowloris_attack = r.data.deny_slowloris_attack
      this.deny_http_fload = r.data.deny_http_fload
      this.control_port = r.data.control_port
      this.limit_connection = r.data.limit_connection
    }).catch(e=>{
      console.log(e)
    })
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapGetters(['getterListWPRegion']),
  },
  methods: {
    ...mapActions(['getListWPRegion', 'updateAPIPlan', 'getPlanDetail']),
    submitUpdatePlan() {
      const data = {
        id: this.$route.params.id,
        name: this.name,
        speed: parseInt(this.speed),
        num_ip: parseInt(this.num_ip),
        price: parseInt(this.price),
        deny_botnet: parseInt(this.deny_botnet),
        deny_syn_fload: parseInt(this.deny_syn_fload),
        deny_udp_fload: parseInt(this.deny_udp_fload),
        deny_icmp: parseInt(this.deny_icmp),
        deny_gre_fload: parseInt(this.deny_gre_fload),
        deny_slowloris_attack: parseInt(this.deny_slowloris_attack),
        deny_http_fload: parseInt(this.deny_http_fload),
        deny_tos_fload: parseInt(this.deny_tos_fload),
        limit_connection: parseInt(this.limit_connection),
        control_port: parseInt(this.control_port),
      }
      // console.log('submitUpdatePlan', data)
      this.updateAPIPlan(data)
    },
    onFileChange(event) {
      // eslint-disable-next-line prefer-destructuring
      this.file = event.target.files[0]
      event.srcElement.parentElement.children[0].textContent = event.target.files[0].name
    },
    onFileChangeImg1(event) {
      // eslint-disable-next-line prefer-destructuring
      this.file1 = event.target.files[0]
      event.srcElement.parentElement.children[0].textContent = event.target.files[0].name
    },
    onFileChangeImg2(event) {
      // eslint-disable-next-line prefer-destructuring
      this.file2 = event.target.files[0]
      event.srcElement.parentElement.children[0].textContent = event.target.files[0].name
    },
    onFileChangeImg3(event) {
      // eslint-disable-next-line prefer-destructuring
      this.file3 = event.target.files[0]
      event.srcElement.parentElement.children[0].textContent = event.target.files[0].name
    },
    onFileChangeImg4(event) {
      // eslint-disable-next-line prefer-destructuring
      this.file4 = event.target.files[0]
      event.srcElement.parentElement.children[0].textContent = event.target.files[0].name
    },
  },
}
</script>

<style scoped>
#preview {
  /*align-items: center;*/
}

#preview img {
  max-width: 200px;
  max-height: 200px;
  object-fit: cover;
}

.input-file {
  border: 1px dashed #ccc;
  display: block;
  width: 100%;
  height: 150px;
}
.input-file-list {
  border: 1px dashed #ccc;
  display: block;
  width: 100%;
  height: 150px;
}

.label-input-file {
  position: relative;
  width: 100%;
}

.label-input-file b {
  display: block;
  width: 100%;
  font-weight: normal;
  text-align: center;
  padding-top: 73px;
  cursor: pointer;
}

.label-input-file input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

</style>
